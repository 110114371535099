export const modules = ['DASHBOARD', 'CUSTOMERS', 'LOANS', 'ADMIN'];

const booleanOptions = [
    {
        label: 'True',
        value: 'True'
    },
    {
        label: 'False',
        value: 'False'
    }
]

export const indianStatesAndUTs = [
    { label: 'Andhra Pradesh', value: 'ANDHRA_PRADESH' },
    { label: 'Arunachal Pradesh', value: 'ARUNACHAL_PRADESH' },
    { label: 'Assam', value: 'ASSAM' },
    { label: 'Bihar', value: 'BIHAR' },
    { label: 'Chhattisgarh', value: 'CHHATTISGARH' },
    { label: 'Goa', value: 'GOA' },
    { label: 'Gujarat', value: 'GUJARAT' },
    { label: 'Haryana', value: 'HARYANA' },
    { label: 'Himachal Pradesh', value: 'HIMACHAL_PRADESH' },
    { label: 'Jharkhand', value: 'JHARKHAND' },
    { label: 'Karnataka', value: 'KARNATAKA' },
    { label: 'Kerala', value: 'KERALA' },
    { label: 'Madhya Pradesh', value: 'MADHYA_PRADESH' },
    { label: 'Maharashtra', value: 'MAHARASHTRA' },
    { label: 'Manipur', value: 'MANIPUR' },
    { label: 'Meghalaya', value: 'MEGHALAYA' },
    { label: 'Mizoram', value: 'MIZORAM' },
    { label: 'Nagaland', value: 'NAGALAND' },
    { label: 'Odisha', value: 'ODISHA' },
    { label: 'Punjab', value: 'PUNJAB' },
    { label: 'Rajasthan', value: 'RAJASTHAN' },
    { label: 'Sikkim', value: 'SIKKIM' },
    { label: 'Tamil Nadu', value: 'TAMIL_NADU' },
    { label: 'Telangana', value: 'TELANGANA' },
    { label: 'Tripura', value: 'TRIPURA' },
    { label: 'Uttar Pradesh', value: 'UTTAR_PRADESH' },
    { label: 'Uttarakhand', value: 'UTTARAKHAND' },
    { label: 'West Bengal', value: 'WEST_BENGAL' },
    { label: 'Andaman and Nicobar Islands', value: 'ANDAMAN_AND_NICOBAR_ISLANDS' },
    { label: 'Chandigarh', value: 'CHANDIGARH' },
    { label: 'Dadra and Nagar Haveli and Daman and Diu', value: 'DADRA_AND_NAGAR_HAVELI_AND_DAMAN_AND_DIU' },
    { label: 'Lakshadweep', value: 'LAKSHADWEEP' },
    { label: 'Delhi', value: 'DELHI' },
    { label: 'Puducherry', value: 'PUDUCHERRY' }
];


export const monthData = [
    {month:"January",number:1},
    {month:"February",number:2},                    
    {month:"March",number:3},
    {month:"April",number:4},
    {month:"May",number:5},
    {month:"June",number:6},
    {month:"July",number:7},
    {month:"August",number:8},
    {month:"September",number:9},
    {month:"October",number:10},
    {month:"November",number:11},
    {month:"December",number:12},
  ]

  // task kanban 
  export const taskStatus = [
    {label: 'Pending', value: 'PENDING'},
    {label: 'In-Progress', value: 'IN_PROGRESS'},
    {label: 'Complete', value: 'COMPLETE'}
  ]

  export const statusColors = {
    PENDING: 'primary',
    IN_PROGRESS: 'warning',
    COMPLETE: 'success',
    default: 'secondary',
};

export const lenderConfigFields = (obj) => {
    const {schemeList} = obj;
    const data = [
    // input, dropdown, multiselect, binary
    {
        label: 'Product scheme',
        code: 'productSchemeCode',
        type: 'dropdown',
        tooltip: 'Product scheme mapped with lender',
        value: [],
        options: schemeList
    },
    {
        label: 'Rate of interest',
        code: 'rate_of_interest',
        type: 'number',
        tooltip: 'Rate of interest of lender',
        value: [],
        subFields: [
            {
                label: 'Go',
                code: 'rate_of_interest go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'rate_of_interest go min',
                        type: 'number',
                        value: '',
                        tooltip: ''   
                    },
                    {
                        label: 'Maximum',
                        code: 'rate_of_interest go max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Processing fee',
        code: 'processing_fee',
        type: 'number',
        tooltip: 'Tapfin processing fee for lender',
        value: [],
        subFields: [
            {
                label: 'Go',
                code: 'processing_fee go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'processing_fee go min',
                        type: 'number',
                        value: '',
                        tooltip: ''   
                    },
                    {
                        label: 'Maximum',
                        code: 'processing_fee go max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Loan amount',
        code: 'loan_loan_amount',
        type: 'number',
        value: [],
        tooltip: 'Minimum Number of years customer is doing business',
        subFields: [
            {
                label: 'Go',
                code: 'loan_loan_amount go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'loan_loan_amount go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'loan_loan_amount deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'loan_loan_amount deviation min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Loan tenure',
        code: 'loan_loan_tenure',
        type: 'number',
        value: [],
        tooltip: 'Tenure',
        subFields: [
            {
                label: 'Go',
                code: 'loan_loan_tenure go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'loan_loan_tenure go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    },
                    {
                        label: 'Maximum',
                        code: 'loan_loan_tenure go max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Applicant age',
        code: 'customer_applicant_age',
        type: 'number',
        value: [],
        tooltip: 'Applicant age',
        subFields: [
            {
                label: 'Go',
                code: 'customer_applicant_age go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'customer_applicant_age go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Applicant maturity age',
        code: 'customer_applicant_maturity_age',
        type: 'number',
        value: [],
        tooltip: 'Applicant maturity age',
        subFields: [
            {
                label: 'Go',
                code: 'customer_applicant_maturity_age go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'customer_applicant_maturity_age go max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'customer_applicant_maturity_age deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'customer_applicant_maturity_age deviation min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    },
                    {
                        label: 'Maximum',
                        code: 'customer_applicant_maturity_age deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Applicant maturity age SE',
        code: 'customer_applicant_maturity_age_se',
        type: 'number',
        value: [],
        tooltip: 'Applicant maturity age',
        subFields: [
            {
                label: 'Go',
                code: 'customer_applicant_maturity_age_se go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'customer_applicant_maturity_age_se go max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'customer_applicant_maturity_age_se deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'customer_applicant_maturity_age_se deviation min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    },
                    {
                        label: 'Maximum',
                        code: 'customer_applicant_maturity_age_se deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Positive pincodes',
        code: 'toolkit_possitive_pincode',
        type: 'dropdown',
        value: [],
        tooltip: 'Lendable pincodes',
        subFields: [
            {
                label: 'Go',
                code: 'toolkit_possitive_pincode go',
                type: 'dropdown',
                value: '',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Negative pincodes',
        code: 'toolkit_negative_pincode',
        type: 'dropdown',
        value: [],
        tooltip: 'Non-lendable pincodes',
        subFields: [
            {
                label: 'Go',
                code: 'toolkit_negative_pincode go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Pan verified',
        code: 'customer_pan_verified',
        type: 'dropdown',
        value: [],
        tooltip: 'Customer pan should be verified',
        subFields: [
            {
                label: 'Go',
                code: 'customer_pan_verified go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Aadhar verified',
        code: 'customer_aadhaar_verified',
        type: 'dropdown',
        value: [],
        tooltip: 'Customer aadhar should be verified',
        subFields: [
            {
                label: 'Go',
                code: 'customer_aadhaar_verified go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Face match',
        code: 'customer_face_matched',
        type: 'dropdown',
        value: [],
        tooltip: 'Customer face should be matching',
        subFields: [
            {
                label: 'Go',
                code: 'customer_face_matched go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Name match',
        code: 'customer_name_matched',
        type: 'dropdown',
        value: [],
        tooltip: 'Customer name should be matching',
        subFields: [
            {
                label: 'Go',
                code: 'customer_name_matched go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Entity name match',
        code: 'entity_name_matched',
        type: 'dropdown',
        value: [],
        tooltip: 'Entity name should be matching',
        subFields: [
            {
                label: 'Go',
                code: 'entity_name_matched go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Entity pan verified',
        code: 'entity_pan_verified',
        type: 'dropdown',
        value: [],
        tooltip: 'Entity pan should be verified',
        subFields: [
            {
                label: 'Go',
                code: 'entity_pan_verified go',
                type: 'dropdown',
                options: booleanOptions,
                tooltip: ''
            }
        ]
    },
    {
        label: 'Cibil score',
        code: 'cibil_bureau_score',
        type: 'number',
        value: '',
        tooltip: 'Cibil score of customer',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_bureau_score go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'cibil_bureau_score go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'cibil_bureau_score deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'cibil_bureau_score deviation min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    },
                    {
                        label: 'Maximum',
                        code: 'cibil_bureau_score deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Cibil credit card inquiries in last 6 months',
        code: 'cibil_cc_enquiries_last_six_month',
        type: 'number',
        value: '',
        tooltip: 'Cibil credit card inquiries',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_cc_enquiries_last_six_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_cc_enquiries_last_six_month go max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'cibil_cc_enquiries_last_six_month deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_cc_enquiries_last_six_month deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Cibil credit card DPD in last month',
        code: 'cibil_cc_dpd_last_month',
        type: 'number',
        value: '',
        tooltip: 'Cibil credit card inquiries in last month',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_cc_dpd_last_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'cibil_cc_dpd_last_month go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'cibil_cc_dpd_last_month deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_cc_dpd_last_month deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Credit card DPD first bucket last 3 months',
        code: 'cibil_cc_first_bucket_dpd_last_three_month',
        type: 'number',
        value: '',
        tooltip: 'DPD first bucket credit card',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_cc_first_bucket_dpd_last_three_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_cc_first_bucket_dpd_last_three_month go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Credit card DPD second bucket last 3 months',
        code: 'cibil_cc_second_bucket_dpd_last_three_month',
        type: 'number',
        value: '',
        tooltip: 'DPD second bucket credit card',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_cc_second_bucket_dpd_last_three_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_cc_second_bucket_dpd_last_three_month go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Credit card DPD second bucket last 12 months',
        code: 'cibil_cc_second_bucket_dpd_last_twelve_month',
        type: 'number',
        value: '',
        tooltip: 'DPD second bucket credit card',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_cc_second_bucket_dpd_last_twelve_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_cc_second_bucket_dpd_last_twelve_month go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Cibil credit card overdue',
        code: 'cibil_cc_overdue',
        type: 'number',
        value: '',
        tooltip: 'Cibil credit card overdue',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_cc_overdue go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_cc_overdue go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Cibil DPD last month',
        code: 'cibil_dpd_last_month',
        type: 'number',
        value: '',
        tooltip: 'Cibil credit card overdue',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_dpd_last_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_dpd_last_month go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil DPD first bucket last 3 months',
        code: 'cibil_first_bucket_dpd_last_three_month',
        type: 'number',
        value: '',
        tooltip: 'DPD first bucket last 3 months',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_first_bucket_dpd_last_three_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_first_bucket_dpd_last_three_month go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil DPD second bucket last 3 months',
        code: 'cibil_second_bucket_dpd_last_three_month',
        type: 'number',
        value: '',
        tooltip: 'DPD second bucket last 3 months',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_second_bucket_dpd_last_three_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_second_bucket_dpd_last_three_month go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil DPD third bucket last 6 months',
        code: 'cibil_third_bucket_dpd_last_six_month',
        type: 'number',
        value: '',
        tooltip: 'DPD third bucket last 6 months',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_third_bucket_dpd_last_six_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_third_bucket_dpd_last_six_month go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil DPD fourth bucket last 12 months',
        code: 'cibil_fourth_bucket_dpd_last_twelve_month',
        type: 'number',
        value: '',
        tooltip: 'DPD fourth bucket last 12 months',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_fourth_bucket_dpd_last_twelve_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_fourth_bucket_dpd_last_twelve_month go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil writeoffs last 36 months',
        code: 'cibil_writeoff_last_thirty_six_month',
        type: 'number',
        value: '',
        tooltip: 'Cibil writeoffs last 36 months',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_writeoff_last_thirty_six_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_writeoff_last_thirty_six_month go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'cibil_writeoff_last_thirty_six_month deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_writeoff_last_thirty_six_month deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil substandard last 2 years',
        code: 'cibil_substandard_last_two_year',
        type: 'number',
        value: '',
        tooltip: 'Cibil substandard last 2 years',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_substandard_last_two_year go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_substandard_last_two_year go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil SMA last 12 months',
        code: 'cibil_sma_last_twelve_month',
        type: 'number',
        value: '',
        tooltip: 'Cibil SMA last 12 months',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_sma_last_twelve_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_sma_last_twelve_month go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil willful defaulter',
        code: 'cibil_willfull_defaulter',
        type: 'number',
        value: '',
        tooltip: 'Cibil willful defaulter',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_willfull_defaulter go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_willfull_defaulter go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Cibil credit card writeoffs last 36 months',
        code: 'cibil_cc_writeoff_last_thirty_six_month',
        type: 'number',
        value: '',
        tooltip: 'Cibil credit card writeoffs last 36 months',
        subFields: [
            {
                label: 'Go',
                code: 'cibil_cc_writeoff_last_thirty_six_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_cc_writeoff_last_thirty_six_month go max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'cibil_cc_writeoff_last_thirty_six_month deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cibil_cc_writeoff_last_thirty_six_month deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Bank statement ABB',
        code: 'bankstatement_abb',
        type: 'number',
        value: '',
        tooltip: 'Bank statement ABB',
        subFields: [
            {
                label: 'Go',
                code: 'bankstatement_abb go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'bankstatement_abb go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'bankstatement_abb deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'bankstatement_abb deviation min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    },
                    {
                        label: 'Maximum',
                        code: 'bankstatement_abb deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Bank statement ABB SE',
        code: 'bankstatement_abb_se',
        type: 'number',
        value: '',
        tooltip: 'Bank statement ABB SE',
        subFields: [
            {
                label: 'Go',
                code: 'bankstatement_abb_se go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'bankstatement_abb_se go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'bankstatement_abb_se deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'bankstatement_abb_se deviation min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    },
                    {
                        label: 'Maximum',
                        code: 'bankstatement_abb_se deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Bank statement age',
        code: 'backstatement_age',
        type: 'number',
        value: '',
        tooltip: 'Bank statement age',
        subFields: [
            {
                label: 'Go',
                code: 'backstatement_age go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'backstatement_age go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Bank statement period',
        code: 'bankstatement_period',
        type: 'number',
        value: '',
        tooltip: 'Bank statement period',
        subFields: [
            {
                label: 'Go',
                code: 'bankstatement_period go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'bankstatement_period go min',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Bank statement salary credits',
        code: 'backstatement_number_salary_credits',
        type: 'number',
        value: '',
        tooltip: 'Bank statement salary credits number',
        subFields: [
            {
                label: 'Go',
                code: 'backstatement_number_salary_credits go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'backstatement_number_salary_credits go min',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Bank statement average number of credits SE',
        code: 'backstatement_average_number_of_credits_se',
        type: 'number',
        value: '',
        tooltip: 'Bank statement average number of credits SE',
        subFields: [
            {
                label: 'Go',
                code: 'backstatement_average_number_of_credits_se go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'backstatement_average_number_of_credits_se go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Bank statement check bounce last 6 months',
        code: 'backstatement_check_bounces_last_six_month',
        type: 'number',
        value: '',
        tooltip: 'Bank statement check bounce last 6 months',
        subFields: [
            {
                label: 'Go',
                code: 'backstatement_check_bounces_last_six_month go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'backstatement_check_bounces_last_six_month go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Bank statement check bounce last 6 months SE',
        code: 'backstatement_check_bounces_last_six_month_se',
        type: 'number',
        value: '',
        tooltip: 'Bank statement check bounce last 6 months SE',
        subFields: [
            {
                label: 'Go',
                code: 'backstatement_check_bounces_last_six_month_se go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'backstatement_check_bounces_last_six_month_se go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Bank statement EMI bounce last 3 months',
        code: 'backstatement_emi_bounce_last_three_months',
        type: 'number',
        value: '',
        tooltip: 'Bank statement EMI bounce last 3 months',
        subFields: [
            {
                label: 'Go',
                code: 'backstatement_emi_bounce_last_three_months go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'backstatement_emi_bounce_last_three_months go max',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Trend in turnover',
        code: 'trend_in_turnover_fy',
        type: 'number',
        value: '',
        tooltip: 'Trend in turnover',
        subFields: [
            {
                label: 'Go',
                code: 'trend_in_turnover_fy go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'trend_in_turnover_fy go min',
                        value: '',
                        type: 'number',
                        tooltip: ''
                    }
                ]
            }
        ] 
    },
    {
        label: 'Minimum average bank balance divide EMI ent',
        code: 'min_avg_bank_bal_devide_emi_ent',
        type: 'number',
        value: '',
        tooltip: 'Minimum average bank balance divide EMI ent',
        subFields: [
            {
                label: 'Go',
                code: 'min_avg_bank_bal_devide_emi_ent go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min_avg_bank_bal_devide_emi_ent go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'min_avg_bank_bal_devide_emi_ent deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min_avg_bank_bal_devide_emi_ent deviation min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Minimum banking turnover ent',
        code: 'min_banking_turnover_ent',
        type: 'number',
        value: '',
        tooltip: 'Minimum average bank balance divide EMI ent',
        subFields: [
            {
                label: 'Go',
                code: 'min_banking_turnover_ent go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min_banking_turnover_ent go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'min_banking_turnover_ent deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'min_banking_turnover_ent deviation min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Inward cheque return ent',
        code: 'inward_cheque_return_ent',
        type: 'number',
        value: '',
        tooltip: 'Inward cheque return ent',
        subFields: [
            {
                label: 'Go',
                code: 'inward_cheque_return_ent go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'inward_cheque_return_ent go max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'inward_cheque_return_ent deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'inward_cheque_return_ent deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Outward cheque return ent',
        code: 'outward_cheque_return_ent',
        type: 'number',
        value: '',
        tooltip: 'Outward cheque return ent',
        subFields: [
            {
                label: 'Go',
                code: 'outward_cheque_return_ent go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'outward_cheque_return_ent go max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'outward_cheque_return_ent deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'outward_cheque_return_ent deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'EMI bounce ent',
        code: 'emi_bounce_ent',
        type: 'number',
        value: '',
        tooltip: 'EMI bounce ent',
        subFields: [
            {
                label: 'Go',
                code: 'emi_bounce_ent go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'emi_bounce_ent go max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'emi_bounce_ent deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'emi_bounce_ent deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Cash deposit againt EMI bounce ent',
        code: 'cash_deposite_against_emi_bounce_ent',
        type: 'number',
        value: '',
        tooltip: 'Cash deposit againt EMI bounce ent',
        subFields: [
            {
                label: 'Go',
                code: 'cash_deposite_against_emi_bounce_ent go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cash_deposite_against_emi_bounce_ent go max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'cash_deposite_against_emi_bounce_ent deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Maximum',
                        code: 'cash_deposite_against_emi_bounce_ent deviation max',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'CCR score ent',
        code: 'ccr_score_ent',
        type: 'number',
        value: '',
        tooltip: 'CCR score ent',
        subFields: [
            {
                label: 'Go',
                code: 'ccr_score_ent go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'ccr_score_ent go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'ccr_score_ent deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'ccr_score_ent deviation min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'CMR score ent',
        code: 'cmr_score_ent',
        type: 'number',
        value: '',
        tooltip: 'CMR score ent',
        subFields: [
            {
                label: 'Go',
                code: 'cmr_score_ent go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'cmr_score_ent go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'cmr_score_ent deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'cmr_score_ent deviation min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Own contribution vehicle ent',
        code: 'own_contribution_vehicle_ent',
        type: 'number',
        value: '',
        tooltip: 'Own contribution vehicle ent',
        subFields: [
            {
                label: 'Go',
                code: 'own_contribution_vehicle_ent go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'own_contribution_vehicle_ent go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'own_contribution_vehicle_ent deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'own_contribution_vehicle_ent deviation min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
    {
        label: 'Own contribution asset ent',
        code: 'own_contribution_Asset_ent',
        type: 'number',
        value: '',
        tooltip: 'Own contribution asset ent',
        subFields: [
            {
                label: 'Go',
                code: 'own_contribution_Asset_ent go',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'own_contribution_Asset_ent go min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            },
            {
                label: 'Deviation',
                code: 'own_contribution_Asset_ent deviation',
                type: 'number',
                value: '',
                tooltip: '',
                childFields: [
                    {
                        label: 'Minimum',
                        code: 'own_contribution_Asset_ent deviation min',
                        type: 'number',
                        value: '',
                        tooltip: ''
                    }
                ]
            }
        ]
    },
]
return data;
}


export const partnerConfigFields = [
    // input, dropdown, multiselect, binary
    {
        label: 'Vintage of customer',
        code: 'customerVintage',
        type: 'number',
        value: [],
        tooltip: 'Minimum Number of years customer is doing business',
    },
    {
        label: 'Revenue',
        code: 'minRevenue',
        type: 'number',
        value: [],
        tooltip: 'Minimum revenue of business required to be eligible for this lender'
    },
    {
        label: 'Minimum interest',
        code: 'minInterest',
        type: 'number',
        value: [],
        tooltip: 'Minimum interest charged by lender'
    },
    {
        label: 'Maximum interest',
        code: 'maxInterest',
        type: 'number',
        value: [],
        tooltip: 'Maximum interest charged by lender'
    },
    {
        label: 'Profit',
        code: 'minProfit',
        type: 'number',
        value: [],
        tooltip: 'Minimum profit of business required to be eligible for this lender'
    },
    {
        label: 'Minimum loan amount',
        code: 'minLoanAmount',
        type: 'numbr',
        value: [],
        tooltip: 'Minimum loan amount offered by lender'
    },
    {
        label: 'Maximum loan amount',
        code: 'maxLoanAmount',
        type: 'number',
        value: [],
        tooltip: 'Maximum loan amount offered by lender'
    },
];

export const productSchemeConfigFields = [
    // input, dropdown, multiselect, binary
    {
        label: 'Processing fee',
        code: 'pf',
        type: 'number',
        value: [],
        tooltip: 'Processing fee for scheme'
    },
    {
        label: 'CGST',
        code: 'cgst',
        type: 'number',
        value: [],
        tooltip: 'CGST for scheme'
    },
    {
        label: 'SGST',
        code: 'sgst',
        type: 'number',
        value: [],
        tooltip: 'SGST for scheme'
    },
    {
        label: 'Interest rate',
        code: 'interestRate',
        type: 'number',
        value: [],
        tooltip: 'Interest rate for scheme'
    },
    {
        label: 'Duration',
        code: 'duration',
        type: 'number',
        value: [],
        tooltip: 'Duration in scheme'
    }
];

export const reKycComments = {
    'cin': ['Provided CIN does not match with other company details', 'Invalid CIN'],
    'udyam': ['Provided udyam number does not match with company details', 'Invalid udyam'],
    'gst': ['Provided GST does not match with company details', 'Invalid GSTIN'],
    'businessIncorporationYear': ['Wrong business incorporation date', 'Others'],
    'pbtProjected': ['Wrong projected PBT'],
    'businessCategory': ['Wrong business category'],
    'entityType': ['Wrong entity type'],
    'typeOfBusiness': ['Wrong type of business'],
    'existingVehicles': ['Incorrect existing vehicle numbers'],
    'projectedRevenue': ['Incorrect projected revenue'],
    'pbtLastProvisional': ['Incorrect last provisional PBT'],
    'lastProvisionalRevenue': ['Incorrect provisional revenue'],
    'pbtLastAudited': ['Incorrect audited PBT'],
    'revenueLastAudited': ['Incorrect audited revenue'],
    'aadhaar': ['Incorrect aadhaar', 'Invalid aadhaar'],
    'pan': ['Incorrect PAN', 'Invalid PAN'],
    'numberOfVehicleRequired': ['Increase number of vehicles required', 'Decrease number of vehicles required'],
    'amountRequired': ['Increase amount required', 'Decrease amount required'],
    'city': ['Change city'],
    'manufacturer': ['Change manufacturer'],
    'model': ['Change model'],
    'variant': ['Change varient'],
    'financialStatementLastFY': ['Incorrect financial statement'],
    'financialStatementLastFY-1': ['Incorrect financial statement'],
    'financialStatementLastFY-2': ['Incorrect financial statement'],
    'bankStatement': ['Incorrect bank statement'],
    'loanStatementForExistingLoan': ['Incorrect existing loan statement'],
    'incomeTaxReturn': ['Incorrect doc'],
    'gstReturn': ['Incorrect doc'],
    'sanctionLetterOfLoanLease': ['Incorrect doc'],
    'unitEconomics': ['Incorrect doc'],
    'shareHolding': ['Incorrect doc'],
    'companyProfile': ['Incorrect doc'],
    'memorandumOfAssociation': ['Incorrect doc'],
    'partnershipDeed': ['Incorrect doc'],
    'balanceSheet': ['Incorrect doc'],
    'profitLossStatement': ['Incorrect doc'],
    'projections': ['Incorrect doc']
}


export const agreementDynamicFields = [
    {
        name: 'Name',
        code: 'name',
    },
    {
        name: 'Date',
        code: 'date',
    },
    {
        name: 'Signature',
        code: 'signature',
    },
    {
        name: 'CIN',
        code: 'cin',
    },
    {
        name: 'address',
        code: 'address',
    },
    {
        name: 'Entity Type',
        code: 'entityType',
    },
    {
        name: 'Type of Business',
        code: 'businessType'
    },
    {
        name: 'Day',
        code: 'day'
    },
    {
        name: 'Month',
        code: 'Month'
    },
    {
        name: 'Year',
        code: 'year'
    },
]

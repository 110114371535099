import React, { useState } from 'react';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Table from '../../components/Common/Table';
import taskColumns from './Columns';
import useClass from '../../hooks/useClass';
import Create from './Create';
import Filters from '../../components/Common/Filters';
import { getTaskList } from '../../store/user/userSlice';
import { useSelector } from 'react-redux';
import TaskCanban from '../../components/Kanban/TaskKanban';

const Tasks = () => {
    document.title = "Task | TapFin";

    const { permission, dispatch} = useClass();
    const columns = taskColumns(permission);
    const { list, totalList, loading } = useSelector((state) => (state?.task));
    const [createNewTask, setCreateNewTask] = useState(false);
    const [pagination, setPagination] = useState({page: 1, perPage: 20});

    const fetchTaskList = (params) => {
        const { pagination, searchText, dateRange } = params;
        dispatch(getTaskList({page: pagination?.page, perPage: pagination?.perPage, search: searchText, dateRange: dateRange}))
    }

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="TapFin" breadcrumbItem="Task" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className='d-flex justify-content-between'>
                                    <h5 className="card-title mb-0">Task</h5>
                                    <Filters
                                        fetchDataFromServer={fetchTaskList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        showCreateButton={permission?.TASK?.LIST?.CREATE_NEW_TASK}
                                        onCreateButtonClick={() => {setCreateNewTask(true)}}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <Table 
                                        data={list} 
                                        loading={loading}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        columns={columns}
                                        paginationTotalRows={totalList}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {permission?.TASK?.LIST?.VIEW_TASK_LIST_OWN && !permission?.TASK?.LIST?.VIEW_TASK_LIST_ALL && <Row>
                    <TaskCanban data={list} />
                    </Row>}
                </Container>
            </div>
            <Create 
                isOpen={createNewTask} 
                setIsOpen={setCreateNewTask} 
            />
        </>
    );
};

export default Tasks;
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table, Input, Button, Card, CardBody, CardTitle, Label, FormGroup, CardText } from 'reactstrap';
import useClass from '../../../hooks/useClass';
import { getInvoiceDetailsAction } from '../../../store/loan/loanAction';
import { useSelector } from 'react-redux';
import LoadingButton from '../../../components/Common/LoadingButton';
import { getLenderExtraDetails } from '../../../store/admin/adminData';
import logo from '../../../assets/images/TAPFIN-1.png';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { Heading, Radio } from 'rsuite';

const formatDate = () => {
  const date = new Date();
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

const InvoiceTable = ({loanUid, generateInvoice, setForm, applicationList}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [lenderAddress, setLenderAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { toaster, dispatch } = useClass();
  
  const {invoiceDetails} = useSelector((store) => store?.loanAction)
  const { details } = useSelector((state) => state?.lender);

  const handleSubmit = () => {
    if (lenderAddress) {
      setIsSubmitted(true);
      setForm((prev)=> ({
        ...prev,
        lenderAddress,
        comment:"Invoice Generated",
        reason:"All data is correct",
      }))
      dispatch(getInvoiceDetailsAction({loanUid,lenderAddress}))
    } else {
        toaster.show(true,"Please Select the Address.");
    }
  };

  const handleInput = (key, value) => {
     setLenderAddress(value?.label)
  }

  // get lenderCode
  const findLenderCodeByLoanUid = () => {
    for (const application of applicationList) {
      const foundLoan = application.loan.find(loan => loan.loanUid === loanUid);
      if (foundLoan) {
        return foundLoan.lenderCode; // Return the first found lenderCode
      }
    }
    return null; // Return null if not found
  };


  useEffect(()=> {
    const lenderCode = findLenderCodeByLoanUid()
    dispatch(getLenderExtraDetails(lenderCode));
  },[])
      
  return (
    <Container>
      {/* GST Number Input and Submit Button */}
      {!isSubmitted && (
    //     <Row className='my-4 justify-content-center'>
    //       <Col md="4" className="text-center border w-100">
    //         {/* <Input
    //           type="text"
    //           placeholder="Enter GST Number"
    //           value={gstNumber}
    //           onChange={(e) => setGstNumber(e.target.value)}
    //         /> */}
    //          {/* <Dropdown
    //               label={'Type'}
    //               options={agreementType}
    //               handleInput={handleInput}
    //               required={true}
    //               value={form?.type}
    //               name={'type'}
    //             /> */}
    //         {/* <Dropdown
    //         name={'state'}
    //         handleInput={handleInput}
    //         placeholder={'Select Lender Address'}
    //         options={details?.extraDetails?.map((detail)=>{return({label:detail?.address,value:detail?.address})})}
    //         /> */}
    //          <div style={{ display: 'flex', gap: '10px' }}>
    //   {details?.extraDetails?.map((detail, index) => (
    //     <Card key={index} style={{ width: '300px', margin: '20px' }}>
    //       <CardBody>
    //         <CardTitle tag="h5">Address Option</CardTitle>
    //         <FormGroup check>
    //           <Label check>
    //             <Radio name="addressOption" value={detail.address} />{' '}
    //             {detail.address}
    //           </Label>
    //         </FormGroup>
    //       </CardBody>
    //     </Card>
    //   ))}
    // </div>
    //       </Col>
    //       <Col md="4" >
    //         <Button color="primary" onClick={handleSubmit}>
    //           Submit
    //         </Button>
    //       </Col>
    //     </Row>
    <Row className="my-4 justify-content-center d-flex flex-column">
    <Col md="6" lg="4" className="text-center mb-4 w-100">
      <h4>Select Lender Address</h4>
    </Col>
    <Col md="6" lg="4" className="text-center w-100">
      <div className="d-flex flex-column align-items-center">
        {details?.extraDetails?.map((detail, index) => (
          <Card key={index} className="mb-3 w-100 shadow-sm border-light">
            <CardBody>
              <FormGroup check>
                <Label check>
                  <Radio
                    name="addressOption" 
                    value={detail.address}
                    checked={lenderAddress === detail.address}
                    onChange={() => setLenderAddress(detail.address)} 
                  />{' '}
                  <strong>{detail.address}</strong>
                </Label>
              </FormGroup>
            </CardBody>
          </Card>
        ))}
      </div>
    </Col>
    <Col md="4" className="text-center w-100">
      <Button color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </Col>
  </Row>
      )}

      {/* Invoice Header */}
      {isSubmitted && (
        <>
          <Row className='my-4'>
            <Col>
              <Table bordered>
                <thead>
                  <tr>
                    <th colSpan="2">
                        <div className='d-flex justify-content-between'>
                          <div className='text-left w-100'>
                          <h5>Invoice No: {invoiceDetails?.invoiceNumber}</h5>
                          <p>Invoice Date: {invoiceDetails?.invoiceDate}</p>
                          </div>
                          <div className='w-100'>
                            <img src={logo} alt="logo-light" height="54"></img>
                          </div>
                        </div>
                    </th>
                  </tr>
                  <tr>
                    <th className='w-50'>Details of Service Provider (Tapsys)</th>
                    <th className='w-50'>Details of Service Receiver ({invoiceDetails?.name})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name: Tapsys Private Limited</td>
                    <td>Name: {invoiceDetails?.name}</td>
                  </tr>
                  <tr>
                    <td >Address: I-202, The Trees, Pirojshanagar, Vikhroli East, Mumbai - 400079</td>
                    <td>Address: {invoiceDetails?.address}</td>
                  </tr>
                  <tr>
                    <td>State: Maharashtra</td>
                    <td>State: {invoiceDetails?.state}</td>
                  </tr>
                  <tr>
                    <td>GSTIN: 27AAKCT1534H1ZA</td>
                    <td>GSTIN: {invoiceDetails?.gstNumber}</td>
                  </tr>
                  <tr>
                    <td>PAN NO: AAKCT1534H</td>
                    <td>PAN NO: {invoiceDetails?.panNumber}</td>
                  </tr>
                  <tr>
                    <td>HSN /SAC CODE: 997159</td>
                    <td>Partner Code: {invoiceDetails?.lenderCode}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          {/* Subject Box */}
          <Row className='my-4'>
            <Col>
              <Card>
                <CardBody className='d-flex gap-1'>
                  <CardTitle tag="h5">Subject:</CardTitle>
                  <p>Commission for the month of: {invoiceDetails?.sanctionDate}</p>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Invoice Details Table */}
          <Row className='my-4'>
            <Col style={{ overflowX: 'auto' }}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Customer Name</th>
                    <th>Loan ID</th>
                    <th>Product</th>
                    <th>Loan Amount (INR)</th>
                    <th>Arrangement Commission %</th>
                    <th>Amount (INR)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>TapFin Capital</td>
                    <td>{invoiceDetails?.lenderLoanUid}</td>
                    <td>{invoiceDetails?.productName}</td>
                    <td>{invoiceDetails?.approvedAmount}</td>
                    <td>{invoiceDetails?.lenderCommissionPercentage}%</td>
                    <td>{invoiceDetails?.commissionAmount}</td>
                  </tr>
                  <tr>
                    <td>CGST ({invoiceDetails?.CGST}%)</td>
                    <td colSpan="6" className="text-end">{invoiceDetails?.cgstAmount}</td>
                  </tr>
                  <tr>
                    <td>SGST ({invoiceDetails?.SGST}%)</td>
                    <td colSpan="6" className="text-end">{invoiceDetails?.sgstAmount}</td>
                  </tr>
                  <tr>
                    <td>IGST ({invoiceDetails?.IGST}%)</td>
                    <td colSpan="6" className="text-end">{invoiceDetails?.igstAmount}</td>
                  </tr>
                  <tr>
                    <td>Decimal Amount</td>
                    <td colSpan="6" className="text-end">{invoiceDetails?.decimalValue}</td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td colSpan="6" className="text-end font-weight-bold">INR {invoiceDetails?.invoiceAmount}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="7" className="text-center font-weight-bold">
                      Amount in Words: {invoiceDetails?.invoiceAmountInWords}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>

          {/* Bank Details and Signatory */}
          <Row className='my-4'>
            <Col md="6">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Company Bank Details:</CardTitle>
                  <p>Account Name: M/S. TAPSYS PRIVATE LIMITED</p>
                  <p>Bank Name: IDFC Bank</p>
                  <p>Branch: BKC- NAMAN BRANCH</p>
                  <p>Bank Account No: 10173604524</p>
                  <p>IFSC Code: IDFB0040101</p>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">For TAPSYS PRIVATE LIMITED</CardTitle>
                  <p>AUTHORIZED SIGNATORY</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className='m-4'>
          <Col className="d-flex justify-content-center gap-4">
          <LoadingButton
                buttonContent={'Generate Invoice'}
                isLoading={isLoading}
                onClick={generateInvoice}
                color={'success'}
              />
            </Col>
            {/* <Button onClick={generateInvoice}>Generate Invoice</Button> */}
          </Row>
        </>
      )}
    </Container>
  );
};

export default InvoiceTable;

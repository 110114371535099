import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "../Common/withRouter";
import profileIcon from "../../assets/images/brands/mail_chimp.png";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import useClass from "../../hooks/useClass";

const ProfileMenu = () => {
  const { navigate, server, toaster } = useClass();
  const userDetails = useSelector((state) => state?.userDetails?.details);
  const [profileMenu, setProfileMenu] = useState(false);
  const [notificationMenu, setNotificationMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [loadedNotificationsCount, setLoadedNotificationsCount] = useState(5);
  const [showLoadMoreLink, setShowLoadMoreLink] = useState(false);
  const { instance } = useMsal();

  useEffect(() => {
    const fetchUserNotification = async () => {
      try {
        const response = await server.getUserNotification();
        if (response?.data?.success) {
          const options = response.data.data.map((item) => ({
            id: item.id,
            title: item.title,
            message: item.message,
            createdAt: item.createdAt,
          }));
          setNotifications(options);
          setNotificationCount(options.length);
          // Set initial state of Load More link
          setShowLoadMoreLink(options.length > 5); // Show if more than 5 notifications
        } else {
          throw new Error(response?.data?.message);
        }
      } catch (error) {
        toaster.show(
          true,
          error?.message || "Failed to fetch Notification Type"
        );
      }
    };

    fetchUserNotification();
  }, []);

  const handleLogout = () => {
    navigate('/login')
    localStorage.removeItem("token")
    window.location.reload();

  };

  const loadMoreNotifications = () => {
    const additionalNotifications = notifications.slice(
      loadedNotificationsCount,
      loadedNotificationsCount + 5
    );

    if (additionalNotifications.length > 0) {
      setLoadedNotificationsCount((prev) => prev + additionalNotifications.length);
    }

    // Update the visibility of Load More link
    if (loadedNotificationsCount + additionalNotifications.length >= notificationCount) {
      setShowLoadMoreLink(false); // Hide the Load More link if no more notifications
    }
  };

  const markNotificationAsRead = async (id) => {
    try {
      const response = await server.markNotificationAsRead(id);
      if (response?.data?.success) {
        setNotifications((prevNotifications) =>
          prevNotifications.filter((notification) => notification.id !== id)
        );
        setNotificationCount((prevCount) => prevCount - 1);
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      toaster.show(
        true,
        error?.message || "Failed to mark notification as read"
      );
    }
  };

  const clearAllNotifications = async () => {
    try {
      const response = await server.clearAllNotifications();
      if (response?.data?.success) {
        setNotifications([]);
        setNotificationCount(0);
        setShowLoadMoreLink(false); // Hide Load More when notifications cleared
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      toaster.show(true, error?.message || "Failed to clear notifications");
    }
  };

  const formatTimeAgo = (dateString) => {
    const now = new Date();
    const notificationTime = new Date(dateString);
    const diffInSeconds = Math.floor((now - notificationTime) / 1000);
    const minutes = Math.floor(diffInSeconds / 60);
    const hours = Math.floor(diffInSeconds / 3600);
    const days = Math.floor(diffInSeconds / 86400);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    // Check if the user has scrolled to the bottom
    if (scrollHeight - scrollTop === clientHeight) {
      // Check if there are more notifications to load
      if (loadedNotificationsCount < notificationCount) {
        setShowLoadMoreLink(true); // Show the Load More link
      }
    } else {
      setShowLoadMoreLink(false); // Hide the Load More link
    }
  };

  const toggleNotificationMenu = () => {
    // Toggle the dropdown
    setNotificationMenu(!notificationMenu);
    
    // Hide Load More link when the dropdown is first opened
    if (!notificationMenu) {
      setShowLoadMoreLink(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <Dropdown
          isOpen={notificationMenu}
          toggle={toggleNotificationMenu}
          className="d-inline-block me-3"
        >
          <DropdownToggle
            className="btn header-item"
            id="notification-dropdown"
            tag="button"
          >
            <div className="position-relative">
              <i className="fas fa-bell"></i>
              {notificationCount > 0 && (
                <Badge
                  color="danger"
                  pill
                  className="position-absolute top-0 start-100 translate-middle"
                >
                  {notificationCount}
                </Badge>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-lg dropdown-menu-end p-3"
            style={{ minWidth: "250px" }}
          >
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h6
              className="dropdown-header m-0"
              style={{ 
                fontWeight: "bold", 
                fontSize: "1rem", 
                color: "#000", 
                marginLeft: "0px", // Remove any extra margins
                alignSelf: "flex-start" // Override the centering effect from parent div
              }}
            >
              Notifications
            </h6>
            {notificationCount > 0 && (
              <Button
                color="link"
                className="p-0 text-decoration-none small"
                style={{ fontSize: "0.7rem" }}
                onClick={clearAllNotifications}
              >
                Clear All
              </Button>
            )}
          </div>

            <div
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                scrollbarWidth: "none", 
                msOverflowStyle: "none", 
              }}
              onScroll={handleScroll}
              // ref={(el) => {
              //   if (el) {
              //     el.style.overflowY = "auto";
              //     el.style.overflowX = "hidden"; 
              //     el.style.position = "relative";
              //     el.style.marginRight = "-15px"; 
              //   }
              // }}
            >
              {notificationCount > 0 ? <>{
                notifications.slice(0, loadedNotificationsCount).map((notification) => {
                  return (
                    <DropdownItem
                      key={notification.id}
                      tag="a"
                      href="#"
                      onClick={() => {
                        markNotificationAsRead(notification.id);
                      }}
                      className="position-relative p-2"
                    >
                      <div
                        className="d-flex flex-column justify-content-between"
                        style={{ height: "100%" }}
                      >
                        <div
                          className="flex-grow-1"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "normal",
                          }}
                        >
                          <strong>{notification.title}</strong>:{" "}
                          <span style={{ whiteSpace: "pre-line" }}>
                            {notification.message}
                          </span>
                        </div>
                        <small
                          className="text-muted"
                          style={{
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            marginTop: "auto",
                            textAlign: "right",
                          }}
                        >
                          {formatTimeAgo(notification.createdAt)}
                        </small>
                      </div>
                    </DropdownItem>
                  )
                })
              }
              <div className="d-flex justify-content-center">
                {loadedNotificationsCount != notificationCount && <Button color="link" onClick={loadMoreNotifications}>Load More</Button>}
              </div>
              </> :
                <DropdownItem disabled className="text-center">
                  No notifications
                </DropdownItem>
              }
            </div>
          </DropdownMenu>
        </Dropdown>

        <Dropdown
          isOpen={profileMenu}
          toggle={() => setProfileMenu(!profileMenu)}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item d-flex align-items-center"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={profileIcon}
              alt="Header Avatar"
            />
            <span className="d-none d-xl-inline-block ms-2 me-2">
              {userDetails?.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag={Link} to="/userprofile">
              <i className="ri-user-line align-middle me-2" />
              Profile
            </DropdownItem>
            <div className="dropdown-divider" />
            <Link onClick={handleLogout} className="dropdown-item">
              <i className="ri-shut-down-line align-middle me-2 text-danger" />
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
};

export default withRouter(ProfileMenu);
